.fude-table.languages {
  background: transparent !important;
  box-shadow: none;
}
.fude-table.languages .theme--light.v-table thead th {
  color: black;
}
.fude-table.languages .v-datatable {
  background: transparent !important;
}
.fude-table.languages .fude-table-data {
  padding: 24px;
}
