.app-search .a-title {
  margin-bottom: 8px;
}
.app-search .a-title p {
  margin-bottom: 0;
}
.app-search .a-description p {
  margin-bottom: 0;
}
.app-search .page_row {
  border-bottom-style: dashed !important;
  border-top-style: dashed;
  border-top-color: lightgrey;
  border-width: 2px !important;
}
.app-search .page_row td {
  text-align: center;
}
.app-search .page_row td:link,
.app-search .page_row td:hover {
  background-color: white !important;
}
.app-search .page_row:link,
.app-search .page_row:hover {
  background-color: white !important;
}
.app-search .app-search__fio {
  padding-right: 0!important;
  padding-left: 16px!important;
}
.app-search .app-search__fio div {
  display: flex;
}
.app-search .app-search__fio div i {
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 10px;
  margin: auto 8px auto auto;
}
.app-search .app-search__fio div span {
  display: inline-flex;
  margin: auto;
  flex-grow: 1;
}
.app-search .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: none !important;
  border-color: #cdcdcd;
  border-width: 1px;
  border-style: solid;
}
.app-search .add-filter {
  text-transform: none;
}
.app-search .v-input {
  font-size: 14px;
}
.app-search .v-label {
  font-size: 14px;
  color: inherit;
}
.app-search h3 {
  font-size: 15px;
  word-break: break-word;
}
.app-search .search-message {
  margin: 8px;
  font-style: italic;
  font-weight: bold;
  color: #2196F3;
}
.app-search .search-message.search-message-error {
  color: #F44336;
}
.fude-table.search-result {
  background: transparent !important;
  box-shadow: none;
}
.fude-table.search-result .theme--light.v-table thead th {
  color: black;
}
.fude-table.search-result .v-datatable {
  background: transparent !important;
}
.fude-table.search-result nav {
  display: none;
}
