
             
.app-search-panel {
  .app-search-panel__input {
    width: 75%;
    .v-input__slot {
      min-height: 42px;
      background-color: white !important;
      border-width: 1px !important;
      .v-text-field__slot {
        input {
          margin: auto;
        }
      }

      .v-input__append-inner {
        margin: auto;
      }
    }
  }

  .app-search-panel__button {
    margin-top: 0;
    height: 42px;
    border-radius: 4px;
  }
}
