
             
.app-vacancy-form {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .app-vacancy-form-status__comment {  
    font-size: 14px;
    font-weight: normal;
    color: black;
  }

  .v-text-field--outline {
    .v-input__control {
      .v-input__slot {
        margin-top: 0;
        min-height: 36px;
        border-width: 1px !important;
        border-radius: 4px;

        .v-text-field__slot {
          input {
            margin: auto;
          }
        }

        .v-input__append-inner {
          margin: auto;
        }
      }
    }
  }

  .app-vacancy-form-title {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .app-vacancy-form-main {
    .main__title {
      margin-bottom: 20px;
    }

    .vacancy__title {
      .title__input {
        margin-top: 10px;
      }
    }

    .vacancy__region {
      margin-bottom: 15px;

      .region__input {
        .v-input__slot {
          padding: 0 12px;
          min-height: 36px;
          
          .v-text-field__prefix {
            margin: auto;
          }

          .v-select__selections {
            margin: auto;
            padding: 0;
          }

          .v-input__append-inner {
            margin: auto;
          }
        }
      }
    }

    .vacancy__salary {
      margin-top: 10px;

      .salary__inputs {
        margin-top: 10px;
        display: flex;
        flex-direction: row;

        .salary {
          width: 140px;
          margin-right: 10px;
        }
        
        .salary__currency {
          .v-input__slot {
            width: 130px;
            min-height: 36px;
            border-radius: 4px;
            border: 1px solid rgba(0,0,0,0.54);

            &:hover {
              border: 1px solid !important
            }
            
            .v-text-field__prefix {
              margin: auto;
            }

            .v-select__selections {
              margin: auto;
              padding: 0;
            }

            .v-input__append-inner {
              margin: auto;
            }
          }
        }
      }
    }

    .vacancy__employment {
      margin-bottom: 30px;
    }

    .vacancy__schedule {
      margin-bottom: 30px;
    }

    .vacancy__experience {
      margin-bottom: 20px;
    }

    .vacancy__description {
      .description__input {
        margin-top: 10px;
      }
    }

    .vacancy__information {
      padding: 20px;
      border: 1px solid black;
      border-radius: 4px;

      .information__sex {
        margin-bottom: 10px;
        display: flex;
        justify-content: start;
        align-items: center;

        .sex__select {
          .v-input__slot {
            margin-bottom: 0;
            width: 140px;
            min-height: 36px;
            border-radius: 4px;
            border: 1px solid rgba(0,0,0,0.54);

            &:hover {
              border: 1px solid !important
            }
            
            .v-text-field__prefix {
              margin: auto;
            }

            .v-select__selections {
              margin: auto;
              padding: 0;
            }

            .v-input__append-inner {
              margin: auto;
            }
          }

          .v-text-field__details {
            display: none;
          }
        }
      }

      .information__age {
        display: flex;
        justify-content: start;
        align-items: center;

        .age__input {
          width: 100px;

          .v-input__slot {
            margin-bottom: 0;
            min-height: 36px;
            border-radius: 4px;
            border: 1px solid rgba(0,0,0,0.54);

            &:hover {
              border: 1px solid !important
            }
            
            .v-text-field__prefix {
              margin: auto;
            }

            .v-select__selections {
              margin: auto;
              padding: 0;
            }

            .v-input__append-inner {
              margin: auto;
            }
          }

          .v-text-field__details {
            display: none;
          }
        }

        .age_not_important__input {
          .v-input__control {
            .v-input__slot {
              margin-bottom: 0;
            }
            
            .v-messages {
              display: none;
            }
          }
        }
      }

      .information__moving {
        margin-top: 15px;
      }

      .information__expirience {
        margin-top: 10px;

        .expirience-list {
          display: flex;

          .expirience-list-item {
            .v-text-field__details {
              display: none;
            }
          }

          .v-btn {
            padding: 0;
            margin-top: 0;
            margin-right: 0;
            min-width: 42px;
            width: 42px;
            height: 42px;
          }
        }
      }

      .region__input {
        .v-input__slot {
          padding: 0 12px;
          min-height: 36px;
          
          .v-text-field__prefix {
            margin: auto;
          }

          .v-select__selections {
            margin: auto;
            padding: 0;
          }

          .v-input__append-inner {
            margin: auto;
          }
        }
      }

      .allowed__select {
        .v-input__slot {
          margin-bottom: 0;
          min-height: 36px;
          border-radius: 4px;
          border: 1px solid rgba(0,0,0,0.54);

          &:hover {
            border: 1px solid !important
          }
          
          .v-text-field__prefix {
            margin: auto;
          }

          .v-select__selections {
            margin: auto;
            padding: 0;
          }

          .v-input__append-inner {
            margin: auto;
          }
        }

        .v-text-field__details {
          display: none;
        }
      }

      @media screen and (max-width: 740px) {
        .information__sex {
          margin-bottom: 10px;
          display: block;
          justify-content: start;
          align-items: center;
        }

        .information__age {
          display: block;
          justify-content: start;
          align-items: center;

          .age_not_important__input {
            margin-top: 5px;
            margin-left: 0 !important;
          }
        }
      }
    }
  }

  .app-vacancy-form-contacts {
    .contacts__title {
      margin-bottom: 20px;
    }

    .contacts__details {
      .details__info {
        width: 50%;
        
        .info__input {
          margin-top: 10px
        }
      }
    }
  }

  .action__publish {
    margin-left: 0;
  }
}

.applicant-resume-modal {
    .v-card__title {
      padding-left: 0;
      padding-right: 0;
    }

    .v-card__text {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }

    .v-card__actions {
      padding-left: 0;
      padding-right: 0;
    }

    .experience__input {
      .v-text-field__details {
        margin-top: 4px;
        display: flex;
      }
    }
  }

  @media screen and (max-width: 600px) {
  .app-vacancy-form {
    width: 100%;
  }
}
  
.scroll_here {
  height: 0;
  width: 0;
}
