.fude-table.educations {
  background: transparent !important;
  box-shadow: none;
}
.fude-table.educations .theme--light.v-table thead th {
  color: black;
}
.fude-table.educations .v-datatable {
  background: transparent !important;
}
.fude-table.educations .fude-table-data {
  padding: 24px;
}
