.age-panel .age-items {
  margin-left: -22px;
}
.age-panel .age-items .flex {
  padding-right: 8px;
}
.age-panel .age-items .v-text-field {
  max-width: 150px;
}
.age-panel .v-input--selection-controls {
  margin-top: 0px !important;
}
.age-panel .age-checkbox {
  margin-left: -2px;
}
.age-panel .age-checkbox .v-messages {
  display: none;
}
