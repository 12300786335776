
             
.search-params {
	.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
		box-shadow: none !important;
		border-width: 0px;
	}
}

.little-combo {
	.v-input__slot {
		box-shadow: none !important;
		background: transparent !important;

		.v-input__icon.v-input__icon-- {
			display: none !important;
		}

		.v-select__selections {
			input {
				max-width: 42px;
			}

			.v-select__selection {
				max-width: 100%;
			}
		}
	}
}

.little-combo1 {
	.v-input__slot {
		box-shadow: none !important;
		background: transparent !important;
		
		.v-input__icon.v-input__icon-- {
			display: none !important;
		}

		.v-select__selections {
			input {
				display: none;
			}

			.v-select__selection {
				max-width: 100%;
			}
		}
	}
}
