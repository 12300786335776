
             

.fude-columns {
  display: flex;

  .fude-columns__grow {
    display: inline-flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-direction: column;
  }

  .fude-columns__shrink {
    display: inline-flex;
    flex-grow: 0;
  }
}

.alert-border{
  border-top-color: currentcolor !important;
  border-top-style: solid !important;
  border-top-width: 5px !important;
  border-right-color: currentcolor !important;
  border-right-style: solid !important;
  border-right-width: 5px !important;
  border-bottom-color: currentcolor !important;
  border-bottom-style: solid !important;
  border-bottom-width: 5px !important;
  border-left-color: currentcolor !important;
  border-left-style: solid !important;
  border-left-width: 5px !important;
  border-image-source: initial !important;
  border-image-slice: initial !important;
  border-image-width: initial !important;
  border-image-outset: initial !important;
  border-image-repeat: initial !important;
  color: #ff9800 !important;
  caret-color: #ff9800 !important;

  margin: 4px auto;
  padding: 16px;
  position: relative;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.5, 1);
  transition-delay: 0s;
}

.app-respond-dialog-view {
  padding-top: 0;

  .app-respond-dialog-view__respond-info {
    color: black;
  }

  .app-respond-duplicate-state {
    margin: 8px 0 10px 0px;
    
    .mdi-spin {
      float: left;
      padding-right: 8px;
      color: red;
    }

    .mdi-spin:before {
      animation: mdi-spin 1s infinite linear!important;
    }
  }

  .v-chip {
    .v-chip__content {
      padding: 2px 8px!important;
    }
  }

  .container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .resume-view {
    h3 {
      font-size: 15px;
      word-break: break-word;
    }
  }

  .resume-view > .v-card {
    margin-bottom: 1px;
  }

  .search-message {
    margin: 8px;
    font-style: italic;
    font-weight: bold;
    color: #2196F3;
  }

  .search-message.search-message-error {
    color: #F44336;
  }

  .resume-message {
    color: black;
    font-size: 15px;
    &.resume-message--alert {
      color: red;
    }
  }

  .app-respond-dialog-view__stop-list-info {
    margin-top: 8px;
    display: flex;
    align-items: center;
  }

  .app-respond-dialog-view__vacancies-titles {
    display: flex;
  }

  .app-respond-dialog-view__icon-commands {
    margin-right: 5px;
    cursor: pointer;
    color: white;
  }

  .resume-comments {
    cursor: pointer;
    color: white;
  }
}
