
             
.app-register-dialog {
    max-width: 620px;
    .checkbox-group {
        .v-input__control {
            .v-input__slot {
                margin-bottom: 8px;
            }
            .v-messages {
                display: none;
            }
        }
    }
}
