.app-organization {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.app-organization .app-organization-main {
  margin-top: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.app-organization .app-organization-main .organization-title {
  margin-bottom: 15px;
}
.app-organization .app-organization-main .vacancy-title {
  color: #145ba3;
}
.app-organization .app-organization-main .all-vacancies {
  margin-top: 10px;
  color: #145ba3;
  cursor: pointer;
}
.app-organization .app-organization-footer .organization-link {
  margin-bottom: 10px;
  color: #145ba3;
  cursor: pointer;
}
