
             
.app-organization {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .app-organization-main {
    margin-top: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .organization-title {
      margin-bottom: 15px;
    }

    .vacancy-title {
      color: #145ba3;
    }

    .all-vacancies {
      margin-top: 10px;
      color: #145ba3;
      cursor: pointer;
    }
  }

  .app-organization-footer {
    .organization-link {
      margin-bottom: 10px;
      color: #145ba3;
      cursor: pointer;
    }
  }
}
