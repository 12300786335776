.app-pay-widget {
  width: 100%;
  padding-left: 0px;
}
.payform-tbank {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 2px auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 250px;
}
.payform-tbank-row {
  padding: 15px;
  margin: 2px;
  border-radius: 4px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border: 1px solid #DFE3F3;
  outline: none;
  background-color: #DFE3F3;
  font-size: 15px;
}
.payform-tbank-row:not(:last-child) {
  padding-bottom: 0;
}
.payform-tbank-row:focus {
  background-color: #FFFFFF;
  border: 1px solid #616871;
  border-radius: 4px;
}
.payform-tbank-btn {
  background-color: #FBC520;
  border: 1px solid #FBC520;
  color: #3C2C0B;
}
.payform-tbank-btn:hover {
  background-color: #FAB619;
  border: 1px solid #FAB619;
}
