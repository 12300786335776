.app-active-vacancies {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.app-active-vacancies .app-active-vacancies__title {
  margin-bottom: 30px;
}
.app-active-vacancies .app-active-vacancies__table .v-input--radio-group .v-input__control {
  margin: auto;
}
.app-active-vacancies .app-active-vacancies__table .v-input--radio-group .v-input__control .v-input__slot {
  margin: auto;
}
.app-active-vacancies .app-active-vacancies__table .v-input--radio-group .v-input__control .v-input__slot .v-radio {
  margin-right: 0;
}
.app-active-vacancies .app-active-vacancies__table .v-input--radio-group .v-input__control .v-input__slot .v-radio .v-input--selection-controls__input {
  margin-right: 0;
}
.app-active-vacancies .app-active-vacancies__table .v-input--radio-group .v-input__control .v-messages {
  display: none;
}
.app-active-vacancies .app-active-vacancies__table .vacancy__title:hover {
  cursor: pointer;
}
.app-active-vacancies .app-active-vacancies__table .responds {
  text-decoration: underline;
}
.app-active-vacancies .app-active-vacancies__table .responds:hover {
  cursor: pointer;
}
