.app-vacancies-table-header {
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.app-vacancies-table-header .new-vacancy__btn {
  margin-left: 0;
}
.app-vacancies-table-header .table-header__search .v-input .v-input__control .v-input__slot {
  width: 230px;
}
.app-vacancies-table-header .v-input .v-input__control .v-input__slot {
  width: 200px;
  min-height: 36px;
  border: 1px solid rgba(0, 0, 0, 0.54);
}
.app-vacancies-table-header .v-input .v-input__control .v-input__slot:hover {
  border: 1px solid !important;
}
.app-vacancies-table-header .v-input .v-input__control .v-input__slot .v-text-field__prefix {
  margin: auto;
}
.app-vacancies-table-header .v-input .v-input__control .v-input__slot .v-select__selections {
  margin: auto;
  padding: 0;
}
.app-vacancies-table-header .v-input .v-input__control .v-input__slot .v-input__append-inner {
  margin: auto;
}
.app-vacancies-table-header .v-input .v-text-field__details {
  display: none;
}
