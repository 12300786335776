.app-vacancy-form {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.app-vacancy-form .app-vacancy-form-status__comment {
  font-size: 14px;
  font-weight: normal;
  color: black;
}
.app-vacancy-form .v-text-field--outline .v-input__control .v-input__slot {
  margin-top: 0;
  min-height: 36px;
  border-width: 1px !important;
  border-radius: 4px;
}
.app-vacancy-form .v-text-field--outline .v-input__control .v-input__slot .v-text-field__slot input {
  margin: auto;
}
.app-vacancy-form .v-text-field--outline .v-input__control .v-input__slot .v-input__append-inner {
  margin: auto;
}
.app-vacancy-form .app-vacancy-form-title {
  margin-top: 30px;
  margin-bottom: 30px;
}
.app-vacancy-form .app-vacancy-form-main .main__title {
  margin-bottom: 20px;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__title .title__input {
  margin-top: 10px;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__region {
  margin-bottom: 15px;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__region .region__input .v-input__slot {
  padding: 0 12px;
  min-height: 36px;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__region .region__input .v-input__slot .v-text-field__prefix {
  margin: auto;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__region .region__input .v-input__slot .v-select__selections {
  margin: auto;
  padding: 0;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__region .region__input .v-input__slot .v-input__append-inner {
  margin: auto;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__salary {
  margin-top: 10px;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__salary .salary__inputs {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__salary .salary__inputs .salary {
  width: 140px;
  margin-right: 10px;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__salary .salary__inputs .salary__currency .v-input__slot {
  width: 130px;
  min-height: 36px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.54);
}
.app-vacancy-form .app-vacancy-form-main .vacancy__salary .salary__inputs .salary__currency .v-input__slot:hover {
  border: 1px solid !important;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__salary .salary__inputs .salary__currency .v-input__slot .v-text-field__prefix {
  margin: auto;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__salary .salary__inputs .salary__currency .v-input__slot .v-select__selections {
  margin: auto;
  padding: 0;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__salary .salary__inputs .salary__currency .v-input__slot .v-input__append-inner {
  margin: auto;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__employment {
  margin-bottom: 30px;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__schedule {
  margin-bottom: 30px;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__experience {
  margin-bottom: 20px;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__description .description__input {
  margin-top: 10px;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information {
  padding: 20px;
  border: 1px solid black;
  border-radius: 4px;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .information__sex {
  margin-bottom: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .information__sex .sex__select .v-input__slot {
  margin-bottom: 0;
  width: 140px;
  min-height: 36px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.54);
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .information__sex .sex__select .v-input__slot:hover {
  border: 1px solid !important;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .information__sex .sex__select .v-input__slot .v-text-field__prefix {
  margin: auto;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .information__sex .sex__select .v-input__slot .v-select__selections {
  margin: auto;
  padding: 0;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .information__sex .sex__select .v-input__slot .v-input__append-inner {
  margin: auto;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .information__sex .sex__select .v-text-field__details {
  display: none;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .information__age {
  display: flex;
  justify-content: start;
  align-items: center;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .information__age .age__input {
  width: 100px;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .information__age .age__input .v-input__slot {
  margin-bottom: 0;
  min-height: 36px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.54);
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .information__age .age__input .v-input__slot:hover {
  border: 1px solid !important;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .information__age .age__input .v-input__slot .v-text-field__prefix {
  margin: auto;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .information__age .age__input .v-input__slot .v-select__selections {
  margin: auto;
  padding: 0;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .information__age .age__input .v-input__slot .v-input__append-inner {
  margin: auto;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .information__age .age__input .v-text-field__details {
  display: none;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .information__age .age_not_important__input .v-input__control .v-input__slot {
  margin-bottom: 0;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .information__age .age_not_important__input .v-input__control .v-messages {
  display: none;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .information__moving {
  margin-top: 15px;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .information__expirience {
  margin-top: 10px;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .information__expirience .expirience-list {
  display: flex;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .information__expirience .expirience-list .expirience-list-item .v-text-field__details {
  display: none;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .information__expirience .expirience-list .v-btn {
  padding: 0;
  margin-top: 0;
  margin-right: 0;
  min-width: 42px;
  width: 42px;
  height: 42px;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .region__input .v-input__slot {
  padding: 0 12px;
  min-height: 36px;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .region__input .v-input__slot .v-text-field__prefix {
  margin: auto;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .region__input .v-input__slot .v-select__selections {
  margin: auto;
  padding: 0;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .region__input .v-input__slot .v-input__append-inner {
  margin: auto;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .allowed__select .v-input__slot {
  margin-bottom: 0;
  min-height: 36px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.54);
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .allowed__select .v-input__slot:hover {
  border: 1px solid !important;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .allowed__select .v-input__slot .v-text-field__prefix {
  margin: auto;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .allowed__select .v-input__slot .v-select__selections {
  margin: auto;
  padding: 0;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .allowed__select .v-input__slot .v-input__append-inner {
  margin: auto;
}
.app-vacancy-form .app-vacancy-form-main .vacancy__information .allowed__select .v-text-field__details {
  display: none;
}
@media screen and (max-width: 740px) {
  .app-vacancy-form .app-vacancy-form-main .vacancy__information .information__sex {
    margin-bottom: 10px;
    display: block;
    justify-content: start;
    align-items: center;
  }
  .app-vacancy-form .app-vacancy-form-main .vacancy__information .information__age {
    display: block;
    justify-content: start;
    align-items: center;
  }
  .app-vacancy-form .app-vacancy-form-main .vacancy__information .information__age .age_not_important__input {
    margin-top: 5px;
    margin-left: 0 !important;
  }
}
.app-vacancy-form .app-vacancy-form-contacts .contacts__title {
  margin-bottom: 20px;
}
.app-vacancy-form .app-vacancy-form-contacts .contacts__details .details__info {
  width: 50%;
}
.app-vacancy-form .app-vacancy-form-contacts .contacts__details .details__info .info__input {
  margin-top: 10px;
}
.app-vacancy-form .action__publish {
  margin-left: 0;
}
.applicant-resume-modal .v-card__title {
  padding-left: 0;
  padding-right: 0;
}
.applicant-resume-modal .v-card__text {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}
.applicant-resume-modal .v-card__actions {
  padding-left: 0;
  padding-right: 0;
}
.applicant-resume-modal .experience__input .v-text-field__details {
  margin-top: 4px;
  display: flex;
}
@media screen and (max-width: 600px) {
  .app-vacancy-form {
    width: 100%;
  }
}
.scroll_here {
  height: 0;
  width: 0;
}
