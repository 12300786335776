.app-vacancy {
  height: 100%;
  width: 75%;
  display: flex;
  flex-direction: column;
}
.app-vacancy .app-vacancy-search {
  width: 65%;
  margin: 0 auto;
}
.app-vacancy .app-vacancy-main {
  margin-top: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__owner .app-vacancy__status {
  display: flex;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__owner .responds_link {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  color: #1976d2;
  text-decoration: underline;
  cursor: pointer;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__owner .responds_link:hover {
  color: #145ba3;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__owner .v-btn {
  margin-left: 0;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__title .title__header {
  display: flex;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__title .title__header .header__favorite-button {
  margin: auto 10px;
  cursor: pointer;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__title .title__header .header__favorite-button .v-icon:hover {
  color: #145ba3 !important;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__title .title__salary {
  font-size: 16px;
  font-weight: 600;
  color: green;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__title .region__icon {
  margin-left: 4px;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__company {
  margin: 10px 0;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__company .company__title {
  margin-bottom: 5px;
  font-size: 18px;
  color: #1976d2;
  cursor: pointer;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__company .company__title:hover {
  color: #145ba3;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__description {
  margin-bottom: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__description .ql-align-center {
  text-align: center;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__description .ql-align-right {
  text-align: right;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__description .description__opened {
  color: #68696a;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__description .app-vacancy-main__information {
  margin-bottom: 30px;
  padding: 16px;
  background-color: #E1DEDD;
  border-radius: 5px;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__description .app-vacancy-main__information .app-vacancy-main__research-info-br {
  display: flex;
  justify-content: start;
  flex-direction: column;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__description .app-vacancy-main__information .app-vacancy-main__research-info-br .research-info__text {
  margin-top: auto;
  margin-left: 5px;
  line-height: 1.7;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__description .app-vacancy-main__information .app-vacancy-main__research-info {
  display: flex;
  justify-content: start;
  align-items: center;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__description .app-vacancy-main__information .app-vacancy-main__research-info .research-info__text {
  margin-top: auto;
  margin-left: 5px;
  line-height: 1.7;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__action {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__action .app-vacancy-main__respond {
  margin-left: 0;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__action .app-vacancy-main__report {
  margin-right: 0;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__slots-container {
  display: flex;
  align-items: center;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__slots-container .app-vacancy-main__slots {
  display: inline-block;
  height: 25px;
  padding: 2px 5px;
  border-radius: 3px;
  color: black;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__slots-container .green-background {
  background-color: #A9EA72;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__slots-container .yellow-background {
  background-color: #F7EE8A;
}
.app-vacancy .app-vacancy-main .app-vacancy-main__slots-container .red-background {
  background-color: #FFAAAA;
}
