
             
.app-registration-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  .v-text-field--outline {
    .v-input__control {
      .v-input__slot {
        margin-top: 0;
        min-height: 36px;
        border-width: 1px !important;
        border-radius: 4px;

        .v-text-field__slot {
          input {
            margin: auto;
          }
        }

        .v-input__append-inner {
          margin: auto;
        }
      }
    }
  }

  .app-registration-form-title {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .app-registration-form-main {
    .vacancy__title {
      .title__input {
        margin-top: 10px;
      }
    }
  }
}

@media screen and (min-width: 576px) {
  .app-registration-form {
	  align-items: center;

    .app-registration-form-main {
      width: 30%;
      min-width: 20em;
    }
  }  
}

.app-dialog-card-r {
	padding: 40px 32px;
	width: 400px;
	background: white;
	border-radius: 16px;
	overflow: hidden;
  text-align: center;

	.app-dialog-card__title {
		margin-bottom: 10px; 
	}
}
  
