.search-params .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: none !important;
  border-width: 0px;
}
.little-combo .v-input__slot {
  box-shadow: none !important;
  background: transparent !important;
}
.little-combo .v-input__slot .v-input__icon.v-input__icon-- {
  display: none !important;
}
.little-combo .v-input__slot .v-select__selections input {
  max-width: 42px;
}
.little-combo .v-input__slot .v-select__selections .v-select__selection {
  max-width: 100%;
}
.little-combo1 .v-input__slot {
  box-shadow: none !important;
  background: transparent !important;
}
.little-combo1 .v-input__slot .v-input__icon.v-input__icon-- {
  display: none !important;
}
.little-combo1 .v-input__slot .v-select__selections input {
  display: none;
}
.little-combo1 .v-input__slot .v-select__selections .v-select__selection {
  max-width: 100%;
}
