
             
.birthday-panel {
    .birthday-items {
        .flex {
            padding-right: 8px;
        }
        .v-text-field {
            max-width: 150px;
        }
    }
    .v-input--selection-controls {
        margin-top: 0px !important;
    }
}
