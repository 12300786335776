.table-view .container {
  margin: 0!important;
  max-width: unset!important;
}
.table-view .v-chip {
  margin: 0px;
}
.table-view .v-chip .v-chip__content {
  height: 24px;
}
.table-view .v-divider {
  margin: 4px 0px;
}
.table-view table {
  width: 100%;
}
