
             
.app-main-page {
	.app-main-page__toolbar {
		position: relative;
		box-shadow: none !important;
		background-color: #EEEEEE;
	}

	.v-toolbar__title {
		min-width: max-content;
	}

	.app-main-page__toolbar-a {
		margin-left: 30px;
		margin-right: 40px;
		text-decoration: underline;
		text-underline-offset: 3px;
		text-decoration-thickness: 1px;
		-webkit-text-decoration-style: dashed;
		text-decoration-style: dashed;
	}

	@media screen and (max-width: 600px) {
		.app-main-page__toolbar-a {
			display: none;
		}
	}

	.app-content {
		padding-top: 0 !important;
	}
}

.v-dialog {
	border-radius: 16px;
}

.app-dialog-card {
	padding: 40px 32px;
	width: 350px;
	background: white;
	border-radius: 16px;
	overflow: hidden;

	.app-dialog-card__title {
		margin-bottom: 10px; 
	}
}

.app-cities-list {
	padding: 0;
	height: 480px;
	overflow-y: auto;
	overflow-x: hidden;

	.v-list__tile {
		cursor: pointer;

		&:hover {
			color: #145ba3;
		}
	}
}

.app-current-city {
	color: #1976d2;
	font-weight: 600;
}

.app-access {
	margin-right: 15px;
	margin-bottom: 7px;
	height: 16px;
	font-size: 14px;
}
