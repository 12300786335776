.v-dialog {
  border-radius: 0 !important;
}
.fude-columns {
  display: flex;
}
.fude-columns .fude-columns__grow {
  display: inline-flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
}
.fude-columns .fude-columns__shrink {
  display: inline-flex;
  flex-grow: 0;
}
.alert-border {
  border-top-color: currentcolor !important;
  border-top-style: solid !important;
  border-top-width: 5px !important;
  border-right-color: currentcolor !important;
  border-right-style: solid !important;
  border-right-width: 5px !important;
  border-bottom-color: currentcolor !important;
  border-bottom-style: solid !important;
  border-bottom-width: 5px !important;
  border-left-color: currentcolor !important;
  border-left-style: solid !important;
  border-left-width: 5px !important;
  border-image-source: initial !important;
  border-image-slice: initial !important;
  border-image-width: initial !important;
  border-image-outset: initial !important;
  border-image-repeat: initial !important;
  color: #ff9800 !important;
  caret-color: #ff9800 !important;
  margin: 4px auto;
  padding: 16px;
  position: relative;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.5, 1);
  transition-delay: 0s;
}
.app-resume-dialog-view {
  padding-top: 0;
}
.app-resume-dialog-view .app-resume-duplicate-state {
  margin: 8px 0 10px 0px;
}
.app-resume-dialog-view .app-resume-duplicate-state .mdi-spin {
  float: left;
  padding-right: 8px;
  color: red;
}
.app-resume-dialog-view .app-resume-duplicate-state .mdi-spin:before {
  animation: mdi-spin 1s infinite linear !important;
}
.app-resume-dialog-view .v-chip .v-chip__content {
  padding: 2px 8px!important;
}
.app-resume-dialog-view .container {
  padding-left: 16px;
  padding-right: 16px;
}
.app-resume-dialog-view .resume-view h3 {
  font-size: 15px;
  word-break: break-word;
}
.app-resume-dialog-view .resume-view > .v-card {
  margin-bottom: 1px;
}
.app-resume-dialog-view .search-message {
  margin: 8px;
  font-style: italic;
  font-weight: bold;
  color: #2196F3;
}
.app-resume-dialog-view .search-message.search-message-error {
  color: #F44336;
}
.app-resume-dialog-view .resume-message {
  color: black;
  font-size: 15px;
}
.app-resume-dialog-view .resume-message.resume-message--alert {
  color: red;
}
.app-resume-dialog-view .app-resume-dialog-view__stop-list-info {
  margin-top: 8px;
  display: flex;
  align-items: center;
}
.app-resume-dialog-view .app-resume-dialog-view__vacancies-titles {
  display: flex;
}
.app-resume-dialog-view .app-resume-dialog-view__icon-commands {
  margin-right: 5px;
  cursor: pointer;
  color: white;
}
.app-resume-dialog-view .resume-comments {
  cursor: pointer;
  color: white;
}
