.fude-table.contacts {
  background: transparent !important;
  box-shadow: none;
}
.fude-table.contacts .theme--light.v-table thead th {
  color: black;
}
.fude-table.contacts .v-datatable {
  background: transparent !important;
}
.fude-table.contacts .fude-table-data {
  padding: 24px;
}
