
             
.simple-search {
	margin: 0px 8px;
	padding-left: 0px;

	.v-chip {
		margin-left: 0;
		height: 36px !important;
	}
	
	.v-btn-toggle{
		.v-btn {
			box-shadow: none !important;
			border-color: #a6a6a6;
			border-width: 1px;
			border-style: solid;
			color:black;
			opacity: unset;
		}
	}
	> .layout {
		padding-bottom: 4px;
	}
	>.flex {
		margin-bottom: 6px;
	}
	.flex.xs3 {
		min-width: 50px;
	}
	.checkbox-group {
		margin-top: 2px;
		margin-left: -2px;
		.v-input__slot {
			margin-bottom: 4px;
		}
		.v-input--selection-controls {
			margin-top: 0px;
			padding-top: 0px;
		}
	}
	.v-input--radio-group {
		margin-top: 0px;
		padding-top: 0px;
		margin-left: -2px;
		.v-messages {
			display: none;
		}
	}
}
