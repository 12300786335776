.app-vacancy-list {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.app-vacancy-list .app-vacancy-list-search {
  width: 65%;
  margin: 0 auto;
}
.app-vacancy-list .app-vacancy-list__title {
  margin-top: 20px;
  margin-bottom: 20px;
}
.app-vacancy-list .app-vacancy-list-main {
  width: 100%;
  height: 100%;
  display: flex;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list__filter {
  margin-right: 40px;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container .app-vacancy-list-main-section .v-list {
  padding: 0;
  height: 100%;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container .app-vacancy-list-main-section .v-list .v-list__tile {
  height: 100%;
  padding: 24px;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container .app-vacancy-list-main-section .v-list .v-list__tile .list-item__neader {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container .app-vacancy-list-main-section .v-list .v-list__tile .list-item__neader .item__title {
  font-size: 22px;
  color: #1976d2;
  white-space: normal;
  cursor: pointer;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container .app-vacancy-list-main-section .v-list .v-list__tile .item__salary {
  font-weight: 700 !important;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container .app-vacancy-list-main-section .v-list .v-list__tile .item__organization {
  margin-right: 5px;
  color: #1976d2;
  cursor: pointer;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container .app-vacancy-list-main-section .v-list .v-list__tile .item__description {
  width: 75%;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container .app-vacancy-list-main-section .v-list .v-list__tile .v-list__tile__title {
  margin-bottom: 5px;
  color: #1976d2;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container .app-vacancy-list-main-section .v-list .v-list__tile .v-list__tile__title:hover {
  color: #145ba3;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container .app-vacancy-list-main-section .v-list .v-list__tile .v-list__tile__action {
  min-width: 40px;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container .app-vacancy-list-main-section .v-list .v-list__tile .v-list__tile__sub-title {
  margin-bottom: 5px;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container .app-vacancy-list-main-section .v-list .v-list__tile .main-section__favorite-button {
  margin: auto;
  cursor: pointer;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container .app-vacancy-list-main-section .v-list .v-list__tile .main-section__favorite-button .v-icon:hover {
  color: #145ba3 !important;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container .app-vacancy-list-main-section .v-list .v-list__tile .main-section__response-button {
  margin: 0;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container .app-vacancy-list-main-section .v-list .v-list__tile .list-item__footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container .app-vacancy-list-main-section .v-list .v-list__tile .list-item__footer .vacancy-opened {
  margin-top: auto;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container .app-vacancy-list-main-section .v-list .v-list__tile .list-item__footer .vacancy-opened .v-list__tile__sub-title {
  margin: 0;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container .app-vacancy-list-main-section .v-list .v-list__tile .list-item__footer .vacancy-opened .list-item__bottom {
  display: flex;
  align-items: center;
  color: black;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container .app-vacancy-list-main-section .v-list .v-list__tile .list-item__footer .vacancy-opened .list-item__bottom .list-item__slots {
  padding: 2px 5px;
  border-radius: 3px;
  color: black;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container .app-vacancy-list-main-section .v-list .v-list__tile .list-item__footer .vacancy-opened .list-item__bottom .green-background {
  background-color: #A9EA72;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container .app-vacancy-list-main-section .v-list .v-list__tile .list-item__footer .vacancy-opened .list-item__bottom .yellow-background {
  background-color: #F7EE8A;
}
.app-vacancy-list .app-vacancy-list-main .app-vacancy-list-main__container .app-vacancy-list-main-section .v-list .v-list__tile .list-item__footer .vacancy-opened .list-item__bottom .red-background {
  background-color: #FFAAAA;
}
