.app-employer {
  height: 100%;
  width: 100%;
  display: flex;
}
.app-employer .app-employer-main {
  width: 85%;
}
.app-employer .app-employer-main .app-employer-main__title {
  margin-top: 30px;
  margin-bottom: 100px;
}
.app-employer .app-employer-main .app-employer-main__title h1 {
  font-size: 44px;
  line-height: 1;
}
.app-employer .app-employer-main .app-employer-main-info .main-info__text {
  margin-bottom: 3px !important;
}
.app-employer .app-employer-main .app-employer-main-info p {
  margin-bottom: 0;
  line-height: 30px;
  font-size: 17px;
}
.app-employer .app-employer-main .app-employer-main-info p b {
  font-size: 22px;
}
.app-employer .app-employer-main .app-employer-main-info .app-employer-main__button {
  margin: 0;
  height: 42px;
  border-radius: 4px;
}
.app-employer .app-employer-main .app-employer-main-bottom {
  display: flex;
  justify-content: space-between;
}
.app-employer .app-employer-main .app-employer-main-bottom .app-employer-main-bottom__container {
  width: 80%;
}
