
             
.app-vacancy-list {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .app-vacancy-list-search {
    width: 65%;
    margin: 0 auto;
  }

  .app-vacancy-list__title {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .app-vacancy-list-main {
    width: 100%;
    height: 100%;
    display: flex;

    .app-vacancy-list__filter {
      margin-right: 40px;
    }

    .app-vacancy-list-main__container {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 0;

      .app-vacancy-list-main-section {

        .v-list {
          padding: 0;
          height: 100%;

          .v-list__tile {
            height: 100%;
            padding: 24px;

            .list-item__neader {
              width: 100%;
              display: flex;
              justify-content: space-between;

              .item__title {
                font-size: 22px;
                color: #1976d2;
                white-space: normal;
                cursor: pointer;
              }
            }

            .item__salary {
              font-weight: 700 !important;
            }

            .item__organization {
              margin-right: 5px;
              color: #1976d2;
              cursor: pointer;
            }

            .item__description {
              width: 75%;
              margin-top: 10px !important;
              margin-bottom: 10px !important;
              white-space: normal;
              display: -webkit-box;
              -webkit-line-clamp: 3;
                      line-clamp: 3; 
              -webkit-box-orient: vertical;
            }

            .v-list__tile__title {
              margin-bottom: 5px;
              color: #1976d2;
              font-size: 18px;
              font-weight: 700;
              line-height: 24px;
              cursor: pointer;

              &:hover {
                color: #145ba3;
              }
            }

            .v-list__tile__action {
              min-width: 40px;
            }

            .v-list__tile__sub-title {
              margin-bottom: 5px;
            }

            .main-section__favorite-button {
              margin: auto;
              cursor: pointer;

              .v-icon {
                &:hover {
                  color: #145ba3 !important;
                }
              }
            }

            .main-section__response-button {
              margin: 0;
            }

            .list-item__footer {
              width: 100%;
              display: flex;
              justify-content: space-between;

              .vacancy-opened {
                margin-top: auto;

                .v-list__tile__sub-title {
                  margin: 0;
                }

                .list-item__bottom {
                  display: flex;
                  align-items: center;
                  color: black;

                  .list-item__slots {
                    padding: 2px 5px;
                    border-radius: 3px;
                    color: black;
                  }

                  .green-background {
                    background-color: #A9EA72;
                  }

                  .yellow-background {
                    background-color: #F7EE8A;
                  }

                  .red-background {
                    background-color: #FFAAAA;
                  }
                }
              }
            }  
          }
        }
      }
    }
  }
}
