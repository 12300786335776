.app-vacancies {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  user-select: none;
}
.app-vacancies .mdi-spin:before {
  animation: mdi-spin 1s infinite linear !important;
}
.app-vacancies .app-statuses-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 0;
  background-color: lightgray;
}
.app-vacancies .app-statuses-list .app-statuses-list__inner {
  flex: 1 1 auto;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  padding: 4px 4px 8px;
}
.app-vacancies .app-statuses-list .app-statuses-list__inner .app-status {
  border-radius: 8px;
  background-color: grey;
  margin: 4px;
  padding: 8px 4px 8px 8px;
  width: 300px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
}
.app-vacancies .app-statuses-list .app-statuses-list__inner .app-status .app-status__header {
  display: flex;
  color: white;
  padding: 4px;
  font-size: 16px;
}
.app-vacancies .app-statuses-list .app-statuses-list__inner .app-status .app-status__content {
  flex: 1 1 auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.app-vacancies .app-statuses-list .app-statuses-list__inner .app-status .app-status__content .fude-column-star {
  overflow-wrap: break-word;
}
.app-vacancies .app-statuses-list .app-statuses-list__inner .app-status .app-status__content .app-status__card {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  user-select: none;
}
.app-vacancies .app-statuses-list .app-statuses-list__inner .app-status .app-status__content .app-status__card .main-section__favorite-button {
  margin-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}
.app-vacancies .app-statuses-list .app-statuses-list__inner .app-status .app-status__content .app-status__card .main-section__favorite-button .v-icon:hover {
  color: #145ba3 !important;
}
.app-vacancies .app-statuses-list .app-statuses-list__inner .app-status .app-status__content .app-status__card .v-card__title {
  flex-wrap: nowrap;
  padding-top: 4px;
  padding-bottom: 4px;
}
.app-vacancies .app-statuses-list .app-statuses-list__inner .app-status .app-status__content .app-status__card .v-card__title .v-btn {
  margin-right: 0;
}
.app-vacancies .app-statuses-list .app-statuses-list__inner .app-status .app-status__content .app-status__card .app-status__card__icon {
  margin-bottom: 3px;
}
.app-vacancies .app-statuses-list .app-statuses-list__inner .app-status .app-status__content .app-status__card .app-status__card__experience {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
