
             
.fude-table.recommendations {
    background: transparent !important;
    box-shadow: none;
    .theme--light.v-table thead th {
        color: black;
    }
    .v-datatable {
        background: transparent !important;
    }
    .fude-table-data{
        padding: 24px;
    }
}
