
             
.app-saved-vacancies {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .app-saved-vacancies__title {
    margin-bottom: 30px;
  }

  .app-saved-vacancies__table {

    .v-input--radio-group {
      .v-input__control {
        margin: auto;

        .v-input__slot {
          margin: auto;

          .v-radio {
            margin-right: 0;

            .v-input--selection-controls__input {
              margin-right: 0;
            } 
          }
        }

        .v-messages {
          display: none;
        }
      }
      
    }

    .vacancy__title {
      &:hover {
        cursor: pointer;
      }
    }

    .responses {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
