
             
.app-vacancies-table-header {
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .new-vacancy__btn {
    margin-left: 0;
  }

  .table-header__search {
    .v-input {
      .v-input__control {
        .v-input__slot {
          width: 230px;
        }
      }
    }
  }

  .v-input {
    .v-input__control {
      .v-input__slot {
        width: 200px;
        min-height: 36px;
        border: 1px solid rgba(0,0,0,0.54);

        &:hover {
          border: 1px solid !important
        }
        
        .v-text-field__prefix {
          margin: auto;
        }

        .v-select__selections {
          margin: auto;
          padding: 0;
        }

        .v-input__append-inner {
          margin: auto;
        }
      }
    }

    .v-text-field__details {
      display: none;
    }
  }
}
