.app-vacancy-list-main__footer {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.app-vacancy-list-main__footer .v-pagination .v-pagination__navigation {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.54);
}
.app-vacancy-list-main__footer .v-pagination .v-pagination__item {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.54);
}
.app-vacancy-list-main__footer .v-input .v-input__control .v-input__slot {
  width: 80px;
  min-height: 36px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.54);
}
.app-vacancy-list-main__footer .v-input .v-input__control .v-input__slot:hover {
  border: 1px solid !important;
}
.app-vacancy-list-main__footer .v-input .v-input__control .v-input__slot .v-text-field__prefix {
  margin: auto;
}
.app-vacancy-list-main__footer .v-input .v-input__control .v-input__slot .v-select__selections {
  margin: auto;
  padding: 0;
}
.app-vacancy-list-main__footer .v-input .v-input__control .v-input__slot .v-input__append-inner {
  margin: auto;
}
.app-vacancy-list-main__footer .v-input .v-text-field__details {
  display: none;
}
.app-vacancy-list-main__footer .footer__button {
  width: 170px;
  height: 36px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.54);
  border-radius: 4px;
  font-size: 15px;
}
.app-vacancy-list-main__footer .footer__button:hover {
  cursor: pointer;
  border: 1px solid;
}
.app-vacancy-list-main__footer .footer__button .button__icon {
  margin-bottom: 1px;
}
