
             
.age-panel {
    .age-items {
        .flex {
            padding-right: 8px;
        }
        .v-text-field {
            max-width: 150px;
        }
        margin-left: -22px;
    }
    .v-input--selection-controls {
        margin-top: 0px !important;
    }
    .age-checkbox {
        margin-left: -2px;
        .v-messages {
            display: none;
        }
    }
}
