.mov-reg-view .container {
  margin: 0!important;
  max-width: unset!important;
}
.mov-reg-view .v-chip {
  margin: 0px;
}
.mov-reg-view .v-chip .v-chip__content {
  height: 24px;
}
.more {
  max-height: 20px;
}
