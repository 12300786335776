
             
.props-view {
  .container {
    margin: 0!important;
    max-width: unset!important;
  }
  .v-chip {
    margin: 0px;
    .v-chip__content {
      height: 24px;
    }
  }
}
