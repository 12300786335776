
             
.my-vacancies {
  margin: 0px 8px;
  padding-left: 0px;

  .a-title {
    margin-bottom: 8px;
    p {
      margin-bottom: 0;
    }
  }

  .a-description {
    p {
      margin-bottom: 0;
    }
  }
}
