.info-view .container {
  margin: 0!important;
  max-width: unset!important;
}
.info-view .v-chip {
  margin: 0px;
}
.info-view .v-chip .v-chip__content {
  height: 24px;
}
.info-view .info-view__fio {
  display: flex;
}
.info-view .info-view__fio .v-icon {
  margin-right: 4px;
  font-size: 16px;
}
