
             
.app-common-profile {
  width: 100%;
  padding-left: 0px;

  .v-btn {
    margin: 0;
  }
}
