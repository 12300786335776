
             
.app-vacancy-filter {
  min-width: 200px;
  max-width: 240px;
  
  .app-vacancy-filter__input {
    .v-input__slot {
      min-height: 36px;
      border-width: 1px !important;

      .v-text-field__slot {
        input {
          margin: auto;
        }
      }

      .v-input__append-inner {
        margin: auto;
      }
    }

    .v-text-field__details {
      display: none;
    }
  }

  .salary__currency {
      .v-input__control {
        margin-bottom: 10px;
        min-height: 36px !important;

        .v-input__slot {
          margin-bottom: 0;
          width: 200px;
          min-height: 36px;
          border-radius: 4px;
          border: 1px solid rgba(0,0,0,0.54);

          &:hover {
            border: 1px solid !important
          }
          
          .v-text-field__prefix {
            margin: auto;
          }

          .v-select__selections {
            margin: auto;
            padding: 0;
          }

          .v-input__append-inner {
            margin: auto;
          }
        }
      }

      .v-text-field__details {
        display: none;
      }
    }
}
