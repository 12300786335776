
             
.app-search {
  .a-title {
    margin-bottom: 8px;

    p {
      margin-bottom: 0;
    }
  }

  .a-description {
    p {
      margin-bottom: 0;
    }
  }

  .page_row {
    border-bottom-style: dashed !important;
    border-top-style: dashed;
    border-top-color: lightgrey;
    border-width: 2px !important;
    td {
      text-align: center;
      &:link,
      &:hover {
        background-color: white !important;
      }
    }
    &:link,
    &:hover {
      background-color: white !important;
    }
  }
  .app-search__fio {
    padding-right: 0!important;
    padding-left: 16px!important;
    div {
      display: flex;
      i {
        display: inline-flex;
        flex-grow: 0;
        flex-shrink: 0;
        font-size: 10px;
        margin: auto 8px auto auto;
      }
      span {
        display: inline-flex;
        margin: auto;
        flex-grow: 1;
      }
    }
  }

  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    box-shadow: none !important;
    border-color: #cdcdcd;
    border-width: 1px;
    border-style: solid;
  }
  .add-filter {
    text-transform: none;
  }
  .v-input {
    font-size: 14px;
  }
  .v-label {
    font-size: 14px;
    color: inherit;
  }
  h3 {
    font-size: 15px;
    word-break: break-word;
  }

  .search-message {
    margin: 8px;
    font-style: italic;
    font-weight: bold;
    color: #2196F3;
  }

  .search-message.search-message-error {
    color: #F44336;
  }
}
.fude-table.search-result {
  background: transparent !important;
  box-shadow: none;
  .theme--light.v-table thead th {
    color: black;
  }
  .v-datatable {
    background: transparent !important;
  }
  nav{
    display: none;
  }
}
