.fude-table.recommendations {
  background: transparent !important;
  box-shadow: none;
}
.fude-table.recommendations .theme--light.v-table thead th {
  color: black;
}
.fude-table.recommendations .v-datatable {
  background: transparent !important;
}
.fude-table.recommendations .fude-table-data {
  padding: 24px;
}
