.app-vacancies {
  height: 100%;
  width: 100%;
  display: flex;
}
.app-vacancies .app-vacancies-main {
  width: 65%;
  margin: auto;
  color: white;
}
.app-vacancies .app-vacancies-main .app-vacancies-main__title {
  margin-bottom: 20px;
}
.app-vacancies .app-vacancies-main .app-vacancies-main__title h1 {
  font-size: 44px;
  line-height: 1;
}
.app-vacancies .app-vacancies-main .app-vacancies-main-info {
  display: flex;
}
.app-vacancies .app-vacancies-main .app-vacancies-main-info .app-vacancies-main-info-stats {
  margin-right: 30px;
}
.app-vacancies .app-vacancies-main .app-vacancies-main-info .app-vacancies-main-info-stats .app-vacancies-main-info-stats__value {
  font-size: 26px;
  white-space: nowrap;
}
.app-vacancies .app-vacancies-main .app-vacancies-main-info .app-vacancies-main-info-stats .app-vacancies-main-info-stats__text {
  font-size: 16px;
}
