.app-vacancy-filter {
  min-width: 200px;
  max-width: 240px;
}
.app-vacancy-filter .app-vacancy-filter__input .v-input__slot {
  min-height: 36px;
  border-width: 1px !important;
}
.app-vacancy-filter .app-vacancy-filter__input .v-input__slot .v-text-field__slot input {
  margin: auto;
}
.app-vacancy-filter .app-vacancy-filter__input .v-input__slot .v-input__append-inner {
  margin: auto;
}
.app-vacancy-filter .app-vacancy-filter__input .v-text-field__details {
  display: none;
}
.app-vacancy-filter .salary__currency .v-input__control {
  margin-bottom: 10px;
  min-height: 36px !important;
}
.app-vacancy-filter .salary__currency .v-input__control .v-input__slot {
  margin-bottom: 0;
  width: 200px;
  min-height: 36px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.54);
}
.app-vacancy-filter .salary__currency .v-input__control .v-input__slot:hover {
  border: 1px solid !important;
}
.app-vacancy-filter .salary__currency .v-input__control .v-input__slot .v-text-field__prefix {
  margin: auto;
}
.app-vacancy-filter .salary__currency .v-input__control .v-input__slot .v-select__selections {
  margin: auto;
  padding: 0;
}
.app-vacancy-filter .salary__currency .v-input__control .v-input__slot .v-input__append-inner {
  margin: auto;
}
.app-vacancy-filter .salary__currency .v-text-field__details {
  display: none;
}
