.app-vacancy-reponds-filter {
  min-width: 200px;
  max-width: 240px;
}
.app-vacancy-reponds-filter .left__input .v-input__slot {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.app-vacancy-reponds-filter .right__input .v-input__slot {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.app-vacancy-reponds-filter .app-vacancy-reponds-filter__input .v-input__slot {
  min-height: 36px;
  border-radius: 0;
  border-width: 1px !important;
}
.app-vacancy-reponds-filter .app-vacancy-reponds-filter__input .v-input__slot .v-text-field__slot input {
  margin: auto;
}
.app-vacancy-reponds-filter .app-vacancy-reponds-filter__input .v-input__slot .v-input__append-inner {
  margin: auto;
}
.app-vacancy-reponds-filter .app-vacancy-reponds-filter__input .v-text-field__details {
  display: none;
}
.app-vacancy-reponds-filter .app-vacancy-reponds-filter__select .v-input__control .v-input__slot {
  width: 240px;
  min-height: 36px;
  border: 1px solid rgba(0, 0, 0, 0.54);
}
.app-vacancy-reponds-filter .app-vacancy-reponds-filter__select .v-input__control .v-input__slot:hover {
  border: 1px solid !important;
}
.app-vacancy-reponds-filter .app-vacancy-reponds-filter__select .v-input__control .v-input__slot .v-text-field__prefix {
  margin: auto;
}
.app-vacancy-reponds-filter .app-vacancy-reponds-filter__select .v-input__control .v-input__slot .v-select__selections {
  margin: auto;
  padding: 0;
}
.app-vacancy-reponds-filter .app-vacancy-reponds-filter__select .v-input__control .v-input__slot .v-input__append-inner {
  margin: auto;
}
