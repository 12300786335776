.simple-search {
  margin: 0px 8px;
  padding-left: 0px;
}
.simple-search .v-chip {
  margin-left: 0;
  height: 36px !important;
}
.simple-search .v-btn-toggle .v-btn {
  box-shadow: none !important;
  border-color: #a6a6a6;
  border-width: 1px;
  border-style: solid;
  color: black;
  opacity: unset;
}
.simple-search > .layout {
  padding-bottom: 4px;
}
.simple-search > .flex {
  margin-bottom: 6px;
}
.simple-search .flex.xs3 {
  min-width: 50px;
}
.simple-search .checkbox-group {
  margin-top: 2px;
  margin-left: -2px;
}
.simple-search .checkbox-group .v-input__slot {
  margin-bottom: 4px;
}
.simple-search .checkbox-group .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
.simple-search .v-input--radio-group {
  margin-top: 0px;
  padding-top: 0px;
  margin-left: -2px;
}
.simple-search .v-input--radio-group .v-messages {
  display: none;
}
