
             
.app-vacancy {
  height: 100%;
  width: 75%;
  display: flex;
  flex-direction: column;

  .app-vacancy-search {
    width: 65%;
    margin: 0 auto;
  }

  .app-vacancy-main {
    margin-top: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .app-vacancy-main__owner {

      .app-vacancy__status {
        display: flex;
      }

      .responds_link {
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 16px;
        color: #1976d2;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          color: #145ba3;
        }
      }

      .v-btn {
        margin-left: 0;
      }
    }

    .app-vacancy-main__title {
      .title__header {
        display: flex;

        .header__favorite-button {
          margin: auto 10px;
          cursor: pointer;

          .v-icon {
            &:hover {
              color: #145ba3 !important;
            }
          }
        }
      }

      .title__salary {
        font-size: 16px;
        font-weight: 600;
        color: green;
      }

      .region__icon {
        margin-left: 4px;
      }
    }

    .app-vacancy-main__company {
      margin: 10px 0;

      .company__title {
        margin-bottom: 5px;
        font-size: 18px;
        color: #1976d2;
        cursor: pointer;

        &:hover {
          color: #145ba3;
        }
      }
    }

    .app-vacancy-main__description {
      margin-bottom: 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .ql-align-center {
        text-align: center;
      }

      .ql-align-right {
        text-align: right;
      }

      .description__opened {
        color: #68696a;
      }

      .app-vacancy-main__information {
        margin-bottom: 30px;
        padding: 16px;
        background-color: #E1DEDD;
        border-radius: 5px;

        .app-vacancy-main__research-info-br {
          display: flex;
          justify-content: start;
          flex-direction: column;

          .research-info__text {
            margin-top: auto;
            margin-left: 5px;
            line-height: 1.7;
          }
        }

        .app-vacancy-main__research-info {
          display: flex;
          justify-content: start;
          align-items: center;

          .research-info__text {
            margin-top: auto;
            margin-left: 5px;
            line-height: 1.7;
          }
        }
      }
    }

    .app-vacancy-main__action {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .app-vacancy-main__respond {
        margin-left: 0;
      }

      .app-vacancy-main__report {
        margin-right: 0;
      }
    }

    .app-vacancy-main__slots-container {
      display: flex;
      align-items: center;

      .app-vacancy-main__slots {
        display: inline-block;
        height: 25px;
        padding: 2px 5px;
        border-radius: 3px;
        color: black;
      }

      .green-background {
        background-color: #A9EA72;
      }

      .yellow-background {
        background-color: #F7EE8A;
      }

      .red-background {
        background-color: #FFAAAA;
      }
    }
  }
}
