
             
.app-respond-list {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .navigation-h1 {
    font-size: 1.1em;
  }

  .navigation-h2 {
    font-size: 1.2em;
  }

  .app-vacancy-reponds__select {
    .v-input__control {
      .v-input__slot {
        width: 250px;
        min-height: 36px;
        border: 1px solid rgba(0,0,0,0.54);

        &:hover {
          border: 1px solid !important
        }
        
        .v-text-field__prefix {
          margin: auto;
        }

        .v-select__selections {
          margin: auto;
          padding: 0;
        }

        .v-input__append-inner {
          margin: auto;
        }
      }
    }
  }

  .app-respond-list-search {
    width: 250px;
  }

  .app-vacancy-reponds__title {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .app-respond-list-main {
    width: 100%;
    height: 100%;
    display: flex;

    .app-vacancy-reponds__filter {
      margin-right: 40px;
    }

    .app-respond-list-main__container {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 0;

      .main-section__header {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }

      .app-respond-list-main-section {
        .v-list {
          padding: 0;
          height: 100%;

          .item__new {
            border: 1px solid #7bcc7e;
          }

          .item__new__research {
            border: 1px solid #1976d2;
          }

          @media screen and (min-width: 701px) {
            .v-list__tile {
              height: 100%;
              padding: 30px 24px;
              display: flex;
              flex-direction: row;
              align-items: start;

              .list-item__neader {
                width: 100%;
                display: flex;
                justify-content: space-between;
              }

              .v-list__tile__title {
                margin-bottom: 5px;
                color: #1976d2;
                font-size: 18px;
                font-weight: 700;
                line-height: 24px;
                cursor: pointer;

                &:hover {
                  color: #145ba3;
                }
              }

              .v-list__tile__action {
                min-width: 40px;
              }
              
              .v-list__tile__sub-title {
                margin-bottom: 5px;
              }

              .main-section__favorite-button {
                margin: auto;
                cursor: pointer;

                .v-icon {
                  &:hover {
                    color: #145ba3 !important;
                  }
                }
              }

              .main-section__response-button {
                margin: 0;
              }

              .list-item__footer {
                width: 100%;
                display: flex;
                justify-content: space-between;

                .vacancy-opened {
                  margin-top: auto;

                  .v-list__tile__sub-title {
                    margin: 0;
                  }
                }
              }  
            }

            .item__status {
              width: 35%;
              display: flex;
              justify-content: end;
            }
          }
        }
      }

      @media screen and (max-width: 700px) {
        .v-list__tile {
          height: 100%;
          padding: 15px 15px;
          display: flex;
          flex-direction: column-reverse;
          align-items: start;

          .list-item__neader {
            width: 100%;
            display: flex;
            justify-content: space-between;
          }

          .v-list__tile__title {
            margin-bottom: 5px;
            color: #1976d2;
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            cursor: pointer;

            &:hover {
              color: #145ba3;
            }
          }

          .v-list__tile__action {
            min-width: 40px;
          }

          .v-list__tile__sub-title {
            margin-bottom: 5px;
          }

          .main-section__favorite-button {
            margin: auto;
            cursor: pointer;

            .v-icon {
              &:hover {
                color: #145ba3 !important;
              }
            }
          }

          .main-section__response-button {
            margin: 0;
          }

          .list-item__footer {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .vacancy-opened {
              margin-top: auto;

              .v-list__tile__sub-title {
                margin: 0;
              }
            }
          }  
        }

        .item__status {
          width: 35%;
          display: flex;
          justify-content: start;

          .v-btn {
            margin-left: 0;
          }
        }
      } 
    }
  }
}
