
             
.app-employer {
  height: 100%;
  width: 100%;
  display: flex;

  .app-employer-main {
    width: 85%;

    .app-employer-main__title {
      margin-top: 30px;
      margin-bottom: 100px;

      h1 {
        font-size: 44px;
        line-height: 1;
      }
    }

    .app-employer-main-info {

      .main-info__text {
        margin-bottom: 3px !important;
      }
      
      p {
        margin-bottom: 0;
        line-height: 30px;
        font-size: 17px;

        b {
          font-size: 22px;
        }
      }

      .app-employer-main__button {
        margin: 0;
        height: 42px;
        border-radius: 4px;
      }
    }

    .app-employer-main-bottom {
      display: flex;
      justify-content: space-between;

      .app-employer-main-bottom__container {
        width: 80%;
      }
    }
  }
}
