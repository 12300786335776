.app-respond-list {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.app-respond-list .navigation-h1 {
  font-size: 1.1em;
}
.app-respond-list .navigation-h2 {
  font-size: 1.2em;
}
.app-respond-list .app-vacancy-reponds__select .v-input__control .v-input__slot {
  width: 250px;
  min-height: 36px;
  border: 1px solid rgba(0, 0, 0, 0.54);
}
.app-respond-list .app-vacancy-reponds__select .v-input__control .v-input__slot:hover {
  border: 1px solid !important;
}
.app-respond-list .app-vacancy-reponds__select .v-input__control .v-input__slot .v-text-field__prefix {
  margin: auto;
}
.app-respond-list .app-vacancy-reponds__select .v-input__control .v-input__slot .v-select__selections {
  margin: auto;
  padding: 0;
}
.app-respond-list .app-vacancy-reponds__select .v-input__control .v-input__slot .v-input__append-inner {
  margin: auto;
}
.app-respond-list .app-respond-list-search {
  width: 250px;
}
.app-respond-list .app-vacancy-reponds__title {
  margin-top: 20px;
  margin-bottom: 20px;
}
.app-respond-list .app-respond-list-main {
  width: 100%;
  height: 100%;
  display: flex;
}
.app-respond-list .app-respond-list-main .app-vacancy-reponds__filter {
  margin-right: 40px;
}
.app-respond-list .app-respond-list-main .app-respond-list-main__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.app-respond-list .app-respond-list-main .app-respond-list-main__container .main-section__header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.app-respond-list .app-respond-list-main .app-respond-list-main__container .app-respond-list-main-section .v-list {
  padding: 0;
  height: 100%;
}
.app-respond-list .app-respond-list-main .app-respond-list-main__container .app-respond-list-main-section .v-list .item__new {
  border: 1px solid #7bcc7e;
}
.app-respond-list .app-respond-list-main .app-respond-list-main__container .app-respond-list-main-section .v-list .item__new__research {
  border: 1px solid #1976d2;
}
@media screen and (min-width: 701px) {
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .app-respond-list-main-section .v-list .v-list__tile {
    height: 100%;
    padding: 30px 24px;
    display: flex;
    flex-direction: row;
    align-items: start;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .app-respond-list-main-section .v-list .v-list__tile .list-item__neader {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .app-respond-list-main-section .v-list .v-list__tile .v-list__tile__title {
    margin-bottom: 5px;
    color: #1976d2;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    cursor: pointer;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .app-respond-list-main-section .v-list .v-list__tile .v-list__tile__title:hover {
    color: #145ba3;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .app-respond-list-main-section .v-list .v-list__tile .v-list__tile__action {
    min-width: 40px;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .app-respond-list-main-section .v-list .v-list__tile .v-list__tile__sub-title {
    margin-bottom: 5px;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .app-respond-list-main-section .v-list .v-list__tile .main-section__favorite-button {
    margin: auto;
    cursor: pointer;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .app-respond-list-main-section .v-list .v-list__tile .main-section__favorite-button .v-icon:hover {
    color: #145ba3 !important;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .app-respond-list-main-section .v-list .v-list__tile .main-section__response-button {
    margin: 0;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .app-respond-list-main-section .v-list .v-list__tile .list-item__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .app-respond-list-main-section .v-list .v-list__tile .list-item__footer .vacancy-opened {
    margin-top: auto;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .app-respond-list-main-section .v-list .v-list__tile .list-item__footer .vacancy-opened .v-list__tile__sub-title {
    margin: 0;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .app-respond-list-main-section .v-list .item__status {
    width: 35%;
    display: flex;
    justify-content: end;
  }
}
@media screen and (max-width: 700px) {
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .v-list__tile {
    height: 100%;
    padding: 15px 15px;
    display: flex;
    flex-direction: column-reverse;
    align-items: start;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .v-list__tile .list-item__neader {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .v-list__tile .v-list__tile__title {
    margin-bottom: 5px;
    color: #1976d2;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    cursor: pointer;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .v-list__tile .v-list__tile__title:hover {
    color: #145ba3;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .v-list__tile .v-list__tile__action {
    min-width: 40px;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .v-list__tile .v-list__tile__sub-title {
    margin-bottom: 5px;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .v-list__tile .main-section__favorite-button {
    margin: auto;
    cursor: pointer;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .v-list__tile .main-section__favorite-button .v-icon:hover {
    color: #145ba3 !important;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .v-list__tile .main-section__response-button {
    margin: 0;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .v-list__tile .list-item__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .v-list__tile .list-item__footer .vacancy-opened {
    margin-top: auto;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .v-list__tile .list-item__footer .vacancy-opened .v-list__tile__sub-title {
    margin: 0;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .item__status {
    width: 35%;
    display: flex;
    justify-content: start;
  }
  .app-respond-list .app-respond-list-main .app-respond-list-main__container .item__status .v-btn {
    margin-left: 0;
  }
}
