
             
.tags-view {
  .v-chip {
    margin: 0px;
    .v-chip__content {
      height: 24px;
    }
  }
}
.more {
  max-height: 20px
}
