
             
.notifications-settings {
  width: 100%;
  padding-left: 0px;

  .notification-info {
    margin-top: 30px;
    display: flex;
    align-items: center;
  }
}
