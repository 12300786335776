.fude-table.moving_regions {
  background: transparent !important;
  box-shadow: none;
}
.fude-table.moving_regions .theme--light.v-table thead th {
  color: black;
}
.fude-table.moving_regions .v-datatable {
  background: transparent !important;
}
.fude-table.moving_regions .fude-table-data {
  padding: 24px;
}
