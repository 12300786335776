
             
.app-organization-list {
    max-width: 1024px;
    .app-organization-list__role-filter {
        .v-input__control {
            width: 100%;
            .v-input--radio-group__input {
                justify-content: center;
            }
        }
    }
    .app-organization-list__role-list {
        display: flex;
        justify-content: center;
    }
}
