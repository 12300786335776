.my-vacancies {
  margin: 0px 8px;
  padding-left: 0px;
}
.my-vacancies .a-title {
  margin-bottom: 8px;
}
.my-vacancies .a-title p {
  margin-bottom: 0;
}
.my-vacancies .a-description p {
  margin-bottom: 0;
}
