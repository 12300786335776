.fude-table.drivings {
  background: transparent !important;
  box-shadow: none;
}
.fude-table.drivings .theme--light.v-table thead th {
  color: black;
}
.fude-table.drivings .v-datatable {
  background: transparent !important;
}
.fude-table.drivings .fude-table-data {
  padding: 24px;
}
